























































import { FormRules, reqReportQuery, reqValidateCode } from "@/api";
import { Form } from "element-ui";
import { Vue, Component } from "vue-property-decorator";

@Component({
  name: "query"
})
export default class extends Vue {
  list: unknown[] = [];
  validImage = "";
  loading = false;

  form = {
    indexNumber: "",
    validateCode: ""
  };

  get rules(): FormRules {
    return {
      indexNumber: [
        {
          required: true,
          message: `请输入查询码`,
          trigger: "blur"
        }
      ],
      validateCode: [
        {
          required: true,
          message: `请输入验证码`,
          trigger: "blur"
        }
      ]
    };
  }

  async initValidCode() {
    try {
      this.loading = true;
      const { data } = await reqValidateCode();
      this.validImage = data;
    } catch (error) {
      //
    } finally {
      this.loading = false;
    }
  }

  async submitHandler() {
    try {
      await (this.$refs.form as Form).validate();
    } catch (error) {
      return;
    }

    try {
      const { indexNumber, validateCode } = this.form;
      const { data } = await reqReportQuery(indexNumber, validateCode);
      this.list = [data];
    } catch (error) {
      this.$message.error(error.response.data.message);
      this.initValidCode();
    }
  }

  created() {
    this.initValidCode();
  }

  padLeftZero(str: string) {
    return ("00" + str).substr(str.length);
  }
  formateTime(time: string) {
    const date = new Date(Number(time));
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate()
    };
    return (
      formatObj.y +
      "-" +
      this.padLeftZero(formatObj.m.toString()) +
      "-" +
      this.padLeftZero(formatObj.d.toString())
    );
  }
}
